import * as cf from '@egr/wcf/modules/cf';
import { ProgressUI } from '../progress';
import './index.css';
import {App} from "../../index";
import {setShareDialogClickAction, showShareDialog} from "../../@types/misc/misc";



// Function to get URL parameter by name
    const getURLParameter = (name: string): string | null => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    };

    // Default language is English
    let currentLanguage = 'en';

    // Try to get language parameter from the URL
    const languageFromURL = getURLParameter('language');
    if (languageFromURL) {
        // Use language from URL if available
        currentLanguage = languageFromURL;
    }
	
// Define translations for each language
const translations: Record<string, Record<string, string>> = {
    en: {
        sharingButton: 'Sharing link',
    },
    pl: {
        sharingButton: 'Link do udostępniania',
    },
	de: {
        sharingButton: 'Link teilen',
    },
	cs: {
        sharingButton: 'Sdílení odkazu',
    },
	fr: {
        sharingButton: 'Partager le lien',
    },
    es: {
        sharingButton: 'Compartir enlace',
    },
};

/**
 * Used for saving and loading a project.
 */
export class PersistenceUI {
   constructor(htmlContainer: HTMLElement, articleManager: cf.ArticleManager, onLoad: (file: Blob, fileType: 'obk' | 'pec') => Promise<void>) {
        const share: HTMLButtonElement = document.createElement('button');
        share.className = 'save export-item';
		share.innerText = translations[currentLanguage]?.sharingButton || translations['en'].sharingButton;
        share.onclick = this.onShareClicked.bind(this, articleManager);
        htmlContainer.appendChild(share);
    }

    private async onShareClicked(articleManager: cf.ArticleManager): Promise<void> {
        ProgressUI.beginLoading();
        const articleTitle : HTMLElement | null = document.getElementById('article-title');
        await articleManager.synchronizeSession(true); // so the server has all new data and we can save it in the next line
        articleManager.initializeSession()
        const pathToSavedScene: string = await articleManager.session.session.saveSession(null);
        let persistenceUrl : string = await this.storePersistenceFile(pathToSavedScene);

        let persistenceBlockTitle : HTMLElement = document.getElementById('persistence-share-title')!;
        persistenceBlockTitle.innerHTML = articleTitle!.innerHTML;

        let persistenceBlockLinkInput = <HTMLInputElement>document.getElementById('persistence-share-link-input')!;
        persistenceBlockLinkInput.value = persistenceUrl;

        let persistenceBlockLinkAnchor = <HTMLAnchorElement>document.getElementById('persistence-share-link-anchor')!;
        persistenceBlockLinkAnchor.href = persistenceUrl;

        let persistenceBlockLinkEmail = <HTMLAnchorElement>document.getElementById('persistence-share-email')!;
        persistenceBlockLinkEmail.href = 'mailto:?subject=Your Rockfon Configuration&body=Click the following link to access the Rockfon configuration: ' + persistenceUrl;

        setShareDialogClickAction();
        showShareDialog();

        ProgressUI.endLoading()
    }

    private async storePersistenceFile(persistence: string): Promise<string> {
        return fetch(App.baseUrl + 'api/persistence/store?obk_url=' + persistence, {method: 'POST'})
            .then(function(response) {
                return response.json()
            })
            .then(function(persistence) {
                return persistence['persistence_url'];
            });
    }
}
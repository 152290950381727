import * as core from '@egr/wcf/modules/core';
import * as tool from '@egr/wcf/modules/core/tool';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import './index.css';
import {ShadowMapping, ShadowPlane} from "@egr/wcf/modules/core/rendering";
import {
    Color3,
    Color4,
    LinesMesh,
    MeshBuilder,
} from "@babylonjs/core";

import * as GUI from '@babylonjs/gui';
import {TextBlock} from "@babylonjs/gui";
import {CameraControl} from "@egr/wcf/modules/core/view";
import {SceneElement} from "@egr/wcf/modules/core/mdl";
import {App} from "../../index";


/**
 * This components creates the views and the controls of the viewer.
 */

export interface ViewerOptions {
    disableCameraPanning: boolean;
    limitCameraDistanceByElementRadius: boolean;
}

// Function to get URL parameter by name
    const getURLParameter = (name: string): string | null => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    };

    // Default language is English
    let currentLanguage = 'en';

    // Try to get language parameter from the URL
    const languageFromURL = getURLParameter('language');
    if (languageFromURL) {
        // Use language from URL if available
        currentLanguage = languageFromURL;
    }
	
// Define translations for each language
const translations: Record<string, Record<string, string>> = {
    en: {
        dimensionsButton: 'Dimensions',
        resetCameraButton: 'Reset camera',
    },
    pl: {
        dimensionsButton: 'Wymiary',
        resetCameraButton: 'Resetowanie kamery',
    },
	de: {
        dimensionsButton: 'Abmessungen',
        resetCameraButton: 'Kamera zurücksetzen',
    },
	cs: {
        dimensionsButton: 'Rozměry',
        resetCameraButton: 'Resetovat kameru',
    },
	fr: {
        dimensionsButton: 'Dimensions',
        resetCameraButton: 'Réinitialisation de l\'appareil photo',
    },
    es: {
        dimensionsButton: 'Dimensiones',
        resetCameraButton: 'Reiniciar cámara',
    },
};



export class ViewerUI {
    private coreApp: core.Application;
    private options: ViewerOptions;
    public dimensionsAreVisible = false;
    private textBlocks : TextBlock[] = [];

    static DEFAULT_CAMERA_POSITION_HUB = new Vector3(0, 0, 0);

    /**
     * @param multiView If true, four views will be created instead of one.
     */
    constructor(coreApp: core.Application, options: ViewerOptions) {
        this.coreApp = coreApp;
        this.options = options;
        if (this.options.disableCameraPanning) {
            const cameraControl = coreApp.viewer.view.cameraControl;
            cameraControl.panningEnabled = false;
            cameraControl.setTarget(Vector3.Zero());
            cameraControl.dblClickZoomToFitOptions.adjustFixedTarget = false;
            cameraControl.orbitInertia = 0.95; // uncomment if you want some movement after the user releases mouse button
        }
        // add user interaction with the articles in the 3d view
        const defaultTool: tool.SelectionDefault = new tool.SelectionDefault(this.coreApp);
        defaultTool.deselectionEnabled = false;
        defaultTool.showMainElementSelection = false;
        defaultTool.showElementInteractors = true;
        this.coreApp.tools.defaultTool = defaultTool;
        this.coreApp.tools.startDefaultTool();

        // add shadows
        this.coreApp.rendering.addShadowGenerator(new ShadowPlane(this.coreApp)); // shadows on the floor
        this.coreApp.rendering.addShadowGenerator(new ShadowMapping(this.coreApp)); // (directional) shadows on the objects
        this.coreApp.viewer.ssaoQuality = "Off"; // screenSpaceAmbientOcclusion shadows, can be lowered/increased/disabled (check performance!), only available with webGL2
        this.coreApp.scene.clearColor = Color4.FromColor3(Color3.FromHexString('#F7F9FA'));

		const miscButtonsContainer = document.getElementById('misc-buttons')!;
        const dimensionButton: HTMLButtonElement = document.createElement('button');
        dimensionButton.className = 'dimensions-button';
        dimensionButton.innerText = translations[currentLanguage]?.dimensionsButton || translations['en'].dimensionsButton;
        dimensionButton.onclick = this.switchDimensionsOfBoundingBox.bind(this);

        const resetCameraButton: HTMLButtonElement = document.createElement('button');
        resetCameraButton.className = 'reset-camera-button';
        resetCameraButton.innerText = translations[currentLanguage]?.resetCameraButton || translations['en'].resetCameraButton;
        resetCameraButton.onclick = this.resetCamera.bind(this);

        miscButtonsContainer.append(dimensionButton);
        miscButtonsContainer.append(resetCameraButton);
    }

    public switchDimensionsOfBoundingBox(): void {
        if(!this.dimensionsAreVisible) {
            this.showDimensionsOfBoundingBox();
            this.dimensionsAreVisible = true;
        } else {
            var meshNames = ["lengthLine", "heightLine", "widthLine"];
            this.destroyDimensionMeshesAndText(meshNames);
            this.dimensionsAreVisible = false;
        }

        this.coreApp.viewer.requestRenderFrame();
    }

    /**
     * If we have multiple articles in the scene (i.e. by loading a .pec), then we need to show the user which one is currently selected by a bounding box.
     */
    public allowMainArticleSelection(value: boolean): void {
        if (this.coreApp.tools.defaultTool instanceof tool.SelectionDefault) {
            this.coreApp.tools.defaultTool.showMainElementSelection = value;
            this.coreApp.tools.defaultTool.deselectionEnabled = value;
        }
    }

    /**
     * Should by called after inserting new elements.
     */
    public resetCamera(): void {
        var camera = this.coreApp.scene.activeCamera;
        camera!.fov = 0.5;

        if (this.options.disableCameraPanning) {
            this.coreApp.viewer.view.cameraControl.setFixedTarget(this.getCenterOfSceneElement());
        }
        if (this.options.limitCameraDistanceByElementRadius) {
            const radiusMultiplier = 3; // should be changed to value you want
            const maxZoomRadius: number = this.getRadiusOfSceneElement() * radiusMultiplier; // maxZoomRadius can also be a fixed value, but we take size of element into count
            this.coreApp.viewer.view.cameraControl.setNavigationArea(maxZoomRadius, this.getCenterOfSceneElement());
        }

        var cameraPosition = CameraControl.DEFAULT_CAMERA_POSITION;

        // Determine default camera position based on the article that is loaded
        if(App.baseArticleNumber === 'hub') {
            cameraPosition = new Vector3(-1.0, 0, -4);
        }

        this.coreApp.viewer.view.cameraControl.setPosition(cameraPosition);
        this.coreApp.viewer.view.cameraControl.zoomToFitElements(this.coreApp.model.elements as SceneElement[]);
    }

    public getCenterOfSceneElement(): Vector3 {
        if (this.coreApp.model.elements.length > 0 && this.coreApp.model.elements[0].boundingBox.isValid()) {
            return this.coreApp.model.elements[0].boundingBox.getCenter();
        }
        return Vector3.Zero();
    }

    private getRadiusOfSceneElement(): number {
        if (this.coreApp.model.elements.length > 0 && this.coreApp.model.elements[0].boundingBox.isValid()) {
            return this.coreApp.model.elements[0].boundingBox.getRadius();
        }
        return 20; // 20 meter, if nothing is in the scene
    }

    public renderDimensionsOfBoundingBoxOnDimensionsChanged(): void {
        var meshNames = ["lengthLine", "heightLine", "widthLine"];
        this.destroyDimensionMeshesAndText(meshNames);
        this.showDimensionsOfBoundingBox();
    }


// Dimensions in mm instead of m

public showDimensionsOfBoundingBox(): void {
    var boundingBox = this.coreApp.model.elements[0].boundingBox;
    var center = boundingBox.getCenter();

    // get the min and max points of the bounding box
    var min = boundingBox.min;
    var max = boundingBox.max;

    var length = ((Math.abs(boundingBox.max.x - boundingBox.min.x)) * 1000).toFixed(0); // Convert to mm and remove decimal places
    var height = ((Math.abs(boundingBox.max.y - boundingBox.min.y)) * 1000).toFixed(0); // Convert to mm and remove decimal places
    var width = ((Math.abs(boundingBox.max.z - boundingBox.min.z)) * 1000).toFixed(0); // Convert to mm and remove decimal places

    // create the lines using the min and max points
    var lengthLine = MeshBuilder.CreateLines("lengthLine", {
        points: [
            new Vector3(min.x, max.y + 0.06, min.z),
            new Vector3(max.x, max.y + 0.06, min.z)
        ]
    }, this.coreApp.scene);

    var heightLine = MeshBuilder.CreateLines("heightLine", {
        points: [
            new Vector3(max.x + 0.06, max.y, center.z),
            new Vector3(max.x + 0.06, min.y, center.z)
        ]
    }, this.coreApp.scene);

    var widthLine = MeshBuilder.CreateLines("widthLine", {
        points: [
            new Vector3(min.x - 0.06, max.y, max.z),
            new Vector3(min.x - 0.06, max.y, min.z)
        ]
    }, this.coreApp.scene);

    // set the color of the line to red
    var color = new Color3(132 / 255, 132 / 255, 132 / 255);
    lengthLine.color = color;
    widthLine.color = color;
    heightLine.color = color;

    this.textBlocks.push(this.createText(length + ' mm', lengthLine, 0, -50));
    this.textBlocks.push(this.createText(width + ' mm', widthLine, 0, -50));
    this.textBlocks.push(this.createText(height + ' mm', heightLine, 50, 0));

    this.coreApp.scene.render();
}











/*
    public showDimensionsOfBoundingBox(): void {
        var boundingBox = this.coreApp.model.elements[0].boundingBox;
        var center = boundingBox.getCenter();

        // get the min and max points of the bounding box
        var min = boundingBox.min;
        var max = boundingBox.max;

        var length = Math.abs(boundingBox.max.x - boundingBox.min.x).toFixed(2);
        var height = Math.abs(boundingBox.max.y - boundingBox.min.y).toFixed(2);
        var width = Math.abs(boundingBox.max.z - boundingBox.min.z).toFixed(2);

        // create the lines using the min and max points
        var lengthLine = MeshBuilder.CreateLines("lengthLine", {
            points: [
                new Vector3(min.x, max.y + 0.06, min.z),
                new Vector3(max.x, max.y + 0.06, min.z)
            ]
        }, this.coreApp.scene);

        var heightLine = MeshBuilder.CreateLines("heightLine", {
            points: [
                new Vector3(max.x + 0.06, max.y, center.z),
                new Vector3(max.x + 0.06, min.y, center.z)
            ]
        }, this.coreApp.scene);

        var widthLine = MeshBuilder.CreateLines("widthLine", {
            points: [
                new Vector3(min.x - 0.06, max.y, max.z),
                new Vector3(min.x - 0.06, max.y, min.z)
            ]
        }, this.coreApp.scene);

        // set the color of the line to red
        var color = new Color3(132 / 255, 132 / 255, 132 / 255);
        lengthLine.color = color;
        widthLine.color = color;
        heightLine.color = color;

        this.textBlocks.push(this.createText(length + ' m', lengthLine, 0, -50));
        this.textBlocks.push(this.createText(width + ' m', widthLine, 0, -50));
        this.textBlocks.push(this.createText(height + ' m', heightLine, 50, 0));

        this.coreApp.scene.render();
    }
	*/

    private createText(text: string, dimensionLine: LinesMesh, offsetX : number, offsetY : number) : TextBlock {
        //gui stuff for text
        let adt = GUI.AdvancedDynamicTexture.CreateFullscreenUI("myUI");

        var dimension = new GUI.TextBlock();
        dimension.text = text;
        dimension.color = "#5C7289";
        dimension.fontSize = 19;
        adt.addControl(dimension);  // must be done BEFORE all link-stuff
        dimension.linkWithMesh(dimensionLine);
        dimension.linkOffsetY = offsetY;
        dimension.linkOffsetX = offsetX;

        return dimension;
    }

    public destroyDimensionMeshesAndText(names: string | any[]): void {
        for (var i = 0; i < names.length; i++) {
            var mesh = this.coreApp.scene.getMeshByName(names[i]);
            if (mesh) {
                mesh.dispose();
            }
        }

        for (var i = 0; i < this.textBlocks.length; i++) {
            if (this.textBlocks[i] instanceof GUI.TextBlock) {
                this.textBlocks[i].dispose();
            }
        }
    }
}

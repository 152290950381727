export class PropertyImageDto {
    key: string
    type: string
    propertyValueImage : string | null | undefined

    constructor(key: string, type: string, propertyValueImage : string | null | undefined) {
        this.key = key;
        this.type = type;
        this.propertyValueImage = propertyValueImage;
    }
}
import {PropertyImageDto} from "./PropertyImageDto";

export class PropertyDto {
    key: string
    property : string
    propertyValue : string | null | undefined
    propertyValueImage : PropertyImageDto

    constructor(key: string, property: string, propertyValue: string | null | undefined, propertyValueImage : PropertyImageDto) {
        this.key = key;
        this.property = property;
        this.propertyValue = propertyValue;
        this.propertyValueImage = propertyValueImage;
    }
}
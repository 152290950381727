export function setShareDialogClickAction() {
    $('#peristence-share-copy-button').click(function() {
        var persistenceUrl = $('#persistence-share-link-input').val();

        $('#persistence-share-copy-button-clicked').show();

        navigator.clipboard.writeText(persistenceUrl);
    });
}

export function showShareDialog() {
    $('.ui-dialog').removeClass('showmark');

    $('#persistence-share').addClass('showmark').dialog();
    $('[aria-describedby="persistence-share"]').addClass('showmark');
    $('#property-editor').addClass('showmark');
}

export function showDownloadsDialog() {
    $('.ui-dialog').removeClass('showmark');

    $('#downloads').addClass('showmark').dialog();
    $('[aria-describedby="downloads"]').addClass('showmark');
    $('#property-editor').addClass('showmark');
}

export function showRequestAQuoteDialog() {
    $('.ui-dialog').removeClass('showmark');

    $('#request-a-quote').addClass('showmark').dialog();
    $('[aria-describedby="request-a-quote"]').addClass('showmark');
    $('#property-editor').addClass('showmark');
}
import { MultiPropertyProvider, Property, PropertyChangedResult, PropertyClass, PropertyValue } from '@egr/wcf/modules/core/prop';
import { isNullOrEmpty } from '@egr/wcf/modules/utils/string';
import { ProgressUI } from '../progress';
import './index.css';
import * as cf from '@egr/wcf/modules/cf';
import {Application} from "@egr/wcf/modules/core";
import {ViewerUI} from "../viewer";
import {PropertyImageDto} from "../../dto/PropertyImageDto";
import {
    AbstractMesh, Color3, DynamicTexture,
    Mesh, MeshBuilder, StandardMaterial,
} from "@babylonjs/core";
import {App} from "../../index";
import {loadVanillaJsForDragDropResize, reDraw, setSide} from "../../@types/canvas/drag-drop";
import {ImagesAppliedDto} from "../../dto/ImagesAppliedDto";

/**
 * For configuring articles.
 * Shows article properties and their options to the user. So he can change properties of an article.
 */
export class PropertyEditorUI {
    private propertyProvider: MultiPropertyProvider; // current selected element, which provides the properties (will be updated by the core)
    public static properties : Array<Property>;
    public static propertiesImage : Array<PropertyImageDto>;

    public static originalImageName : string;
    public static originalImages: string[] = [];
    public static croppedImages: string[] = [];

    private htmlContainer: HTMLElement;
    private coreApp : Application;
    private initialRun = false;

    private allColorsAreShown : boolean = false;
    private dynamicDimensionsAreRendered : boolean = false;

    private imagesApplied : ImagesAppliedDto;
    private activeSide : string;
    private frontSide : string;
    private backSide : string ;
    private canvasBackground : string;

    private parentPropertiesWithColorOverride = ['ppr-ROCKFON_SCR_Screen_Color'];
    private camereResetableProperties = ['[Numeric]ROCKFON_SCR_Width', '[Character]ROCKFON_SCR_DIM_Typ', '[Character]ROCKFON_HUB_Panel_Size', '[Character]ROCKFON_SEN_GSHAPEMT', '[Character]ROCKFON_SEN_GAlignment'];
    private baseArticlesWithImageUpload = ['hdi', 'scr', 'pan'];

    private hubColorProperty : Property;
    private hubColorPropertyValue : PropertyValue;

    private viewerUI : ViewerUI;

    constructor(htmlContainer: HTMLElement, propertyProvider: MultiPropertyProvider, articleManager : cf.ArticleManager, coreApp: Application, viewerUI : ViewerUI) {
        this.propertyProvider = propertyProvider;
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.propertyProvider.eventPropertiesChanged.addListener(this.onPropertiesChanged.bind(this));
        this.htmlContainer = htmlContainer;
        this.coreApp = coreApp;
        this.viewerUI = viewerUI;

        this.imagesApplied = new ImagesAppliedDto();
    }



  private setFrontAndBackMeshes() {
    if (App.baseArticleNumber === 'pan') {
        this.frontSide = '72_ROCKFON_SCR_D3_ACOUSTICS';
        this.backSide = '72_ROCKFON_SCR_D3_ACOUSTICB';

        // Add 'panpanpan' as a class to the body element
        document.body.classList.add('panpanpan');
    } else {
        this.frontSide = '72_ROCKFON_SCR_D3_ACOUSTIC';
        this.backSide = '72_ROCKFON_SCR_D3_ACOUSTICB';

        // Remove 'panpanpan' class from the body element if it exists
        document.body.classList.remove('panpanpan');
    }
}

    private async onPropertiesChanged(result?: PropertyChangedResult): Promise<void> {
        this.setFrontAndBackMeshes();

        if (result !== PropertyChangedResult.Nothing) {
            await this.updatePropertyEditor();
        }
		
		const pcElement = document.querySelector('.ROCKFON_SCR_Screen_Color');
		
		let hasRun = false;

		if (pcElement && !hasRun) {
			const buttonElement = document.createElement('button');
			buttonElement.classList.add('but3');

            if(this.allColorsAreShown)
            {
                buttonElement.classList.add('showme');
            }
			/* buttonElement.textContent = 'Toggle'; */
    
			buttonElement.addEventListener('click', () => {
				pcElement.classList.toggle('showme');
				buttonElement.classList.toggle('showme');

                this.allColorsAreShown = !this.allColorsAreShown;
			});

			pcElement.insertAdjacentElement('afterend', buttonElement);
		}
		
		for(var i = 0; i < this.parentPropertiesWithColorOverride.length; i++) {
            var selector: HTMLElement | null = document.querySelector('.' + this.parentPropertiesWithColorOverride[i]);

            if(selector !== null && App.baseArticleNumber !== null && this.baseArticlesWithImageUpload.includes(App.baseArticleNumber)) {
                selector.appendChild(await this.createUploadImagePropertyItem());

                var uploadButton = document.getElementById('file-upload-button')!;
                uploadButton.addEventListener('click', this.handleFileUpload.bind(this));

                const uploadSideOptions = document.querySelectorAll(".upload-side-option");

                uploadSideOptions.forEach(option => {
                    option.addEventListener("click", this.handleUploadSideOptionClick.bind(this));
                });

                const uploadStepTwoToOneButton = document.getElementById('upload-step-2-to-1') as HTMLButtonElement;
                uploadStepTwoToOneButton.addEventListener('click', this.handleUploadStepTwoToOneClick.bind(this)
				);
				
				// Deze zou wel niet hier horen. 
				const uploadStepThreeToTwoButton = document.getElementById('upload-step-3-to-2') as HTMLButtonElement;
                    uploadStepThreeToTwoButton.addEventListener('click', this.handleUploadStepThreeToTwoClick.bind(this)
				);

                if(this.imagesApplied.both)
                {
                    await this.applyAllImages();
                }

                if(this.imagesApplied.front)
                {
                    this.activeSide = 'front';
                    setSide('front');

                    await this.applyAllImages();
                }

                if(this.imagesApplied.back)
                {
                    this.activeSide = 'back';
                    setSide('back');

                    await this.applyAllImages();
                }

                this.coreApp.viewer.requestRenderFrame();
            }
        }
    }

    private handleUploadStepTwoToOneClick()
    {
        document.getElementById('upload-step-1')!.style.display = 'block';
        document.getElementById('upload-step-2')!.style.display = 'none';
		
		const stepIndicator = document.querySelector('.step-indicator')!;
		stepIndicator.classList.remove('s2', 's3');
		stepIndicator.classList.add('s1');

        PropertyEditorUI.resetFileUpload();
		this.resetValidationsAndOutputs();
    }
	
	
	
	private handleUploadStepThreeToTwoClick()
    {
        document.getElementById('upload-step-2')!.style.display = 'block';
        document.getElementById('upload-step-3')!.style.display = 'none';
		
		const stepIndicator = document.querySelector('.step-indicator')!;
		stepIndicator.classList.remove('s1', 's3');
		stepIndicator.classList.add('s2');
    }
	

    private static resetFileUpload()
    {
        const fileUpload = document.getElementById('file-upload') as HTMLInputElement;
        fileUpload.value = '';
    }

    private handleUploadSideOptionClick(event: Event) {
	
// Poging tot aanpassen req dimensions
const mesh = this.coreApp.model.layers.getMeshesOnLayer((this.activeSide === 'back' ? this.backSide : this.frontSide))[0]; // get mesh
const bbox = mesh.getBoundingInfo().boundingBox;

// Calculate the mesh's size in pixels
// Get the dimensions of the bounding box
const width = bbox.maximum.x - bbox.minimum.x;
const height = bbox.maximum.y - bbox.minimum.y;

const meshSizeInPixels = {"y": height * 500, "x": width * 500};

// Set the div's width and height based on the mesh's size as global variables
(window as any).divWidthCalc = Math.floor(meshSizeInPixels.x * devicePixelRatio * 4 / 100) * 100;
(window as any).divHeightCalc = Math.floor(meshSizeInPixels.y * devicePixelRatio * 4 / 100) * 100;

// Get the HTML element with the class "res"
const resElement = document.querySelector('.res') as HTMLElement;

// Update the content of the element with the calculated dimensions
resElement.textContent = `Use a minimum resolution of ${(window as any).divWidthCalc}x${(window as any).divHeightCalc} pixels.`;
// Einde poging tot aanpassen req dimensions

								
								
	
        const target = event.target as HTMLElement;
        const dataSide = target.getAttribute("data-side");
        if (dataSide) {
            this.activeSide = dataSide;
            setSide(dataSide);

            document.getElementById('upload-step-1')!.style.display = 'none';
            document.getElementById('upload-step-2')!.style.display = 'block';
			
			const stepIndicator = document.querySelector('.step-indicator')!;
			stepIndicator.classList.remove('s1', 's3');
			stepIndicator.classList.add('s2');
	
        }
    }

    public readFileAsBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const base64Data = reader.result as string;
                resolve(base64Data);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    }

public async handleFileUpload() {
    const fileInput = document.getElementById('file-upload') as HTMLInputElement;

    if (fileInput.files !== null) {
        const selectedFile = fileInput.files[0];

        if (selectedFile) {
            this.readFileAsBase64(selectedFile)
                .then(async (base64Data) => {
                    let response = await this.uploadImageToServer(selectedFile.name, base64Data);

                    PropertyEditorUI.originalImageName = selectedFile.name;
                    (PropertyEditorUI.originalImages as any)[this.activeSide] = response['data']['image_url'];

                    await this.applyImageToDiv(base64Data);

                })
                .catch((error) => {
                    console.error('Error reading file:', error);
                });
        }

        PropertyEditorUI.resetFileUpload();
    }
}

private async uploadImageToServer(filename : string, base64: string) : Promise<any>
{
    ProgressUI.beginLoading();

    let data = new FormData();
    data.append('base64', base64);
    data.append('filename', filename);

    return fetch(App.baseUrl + 'api/image-upload',
        {
                method: 'POST',
                body: data,
            }
        )
        .then(function(response) {
            ProgressUI.endLoading();

            return response.json();
        });
}

public initializeFileUpload() {
    const fileInput = document.getElementById('file-upload') as HTMLInputElement;

    fileInput.addEventListener('change', () => {
        this.handleFileUpload();
    });

    fileInput.addEventListener('dragover', (e) => {
        e.preventDefault();
        fileInput.classList.add('dragover');
    });

    fileInput.addEventListener('dragleave', () => {
        fileInput.classList.remove('dragover');
    });

    fileInput.addEventListener('drop', (e) => {
        e.preventDefault();
        fileInput.classList.remove('dragover');
        const file = e.dataTransfer?.files[0];
        if (file) {
            this.readFileAsBase64(file)
                .then((base64Data) => {
                    this.applyImageToDiv(base64Data);
                })
                .catch((error) => {
                    console.error('Error reading file:', error);
                });
        }
    });
}



// The rest of your TypeScript code remains unchanged


    private async applyImageToDiv(base64: string) {
        const mesh = this.coreApp.model.layers.getMeshesOnLayer((this.activeSide === 'back' ? this.backSide : this.frontSide))[0]; // get mesh
        const bbox = mesh.getBoundingInfo().boundingBox;

        // Calculate the mesh's size in pixels
        // Get the dimensions of the bounding box
        const width = bbox.maximum.x - bbox.minimum.x;
        const height = bbox.maximum.y - bbox.minimum.y;

        const meshSizeInPixels = {"y": height * 500, "x": width * 500};

        // Set the div's width and height based on the mesh's size
        const divWidth = meshSizeInPixels.x * devicePixelRatio;
        const divHeight = meshSizeInPixels.y * devicePixelRatio;

        const divThirdStep = document.getElementById('upload-step-3')!;

        // Create the image and add it to the div
        const image = document.createElement('img');
        image.src = base64;

        image.onload = () => {
            const loadedImage = image as HTMLImageElement;
            // Calculate the aspect ratio of the loaded image
            const imageAspectRatio = loadedImage.width / loadedImage.height;

            // Ensure that the imageWidth and imageHeight are in proportion to divWidth and divHeight
            let imageWidth, imageHeight;
            if (divWidth / divHeight > imageAspectRatio) {
                imageWidth = divHeight * imageAspectRatio;
                imageHeight = divHeight;
            } else {
                imageWidth = divWidth;
                imageHeight = divWidth / imageAspectRatio;
            }

            // Create the container div and set its dimensions
			 const canvasWrapper = document.createElement('div');
			canvasWrapper.id = 'canvas-wrapper';
            const containerCanvas = document.getElementById('container-canvas') as HTMLCanvasElement;
            containerCanvas.width = divWidth;
            containerCanvas.height = divHeight;
            containerCanvas.style.width = (containerCanvas.width / devicePixelRatio).toString() + "px";
            containerCanvas.style.height = (containerCanvas.height / devicePixelRatio).toString() + "px";
            containerCanvas.style.backgroundColor = 'gray';
            containerCanvas.style.overflow = 'visible';
            containerCanvas.style.position = 'relative';
            containerCanvas.id = 'container-canvas';

            //this.loadVanillaJsForDragDropResize(imageWidth, imageHeight, base64, this.fetchRGBFromBackground(this.canvasBackground));

            loadVanillaJsForDragDropResize(imageWidth, imageHeight, base64, this.fetchRGBFromBackground(this.canvasBackground));

			canvasWrapper.appendChild(containerCanvas); // Append the canvas to the wrapper

			divThirdStep.appendChild(canvasWrapper);

            const applyToMeshButton = document.getElementById('apply-to-mesh-button')!;
            applyToMeshButton.onclick = this.applyImageToMesh.bind(this, true);

            divThirdStep.appendChild(applyToMeshButton);
        };

        const divSecondStep = document.getElementById('upload-step-2')!;

			divSecondStep.style.display = 'none';

			function adjustDisplay() {
			  if (window.innerWidth < 768) {
				divThirdStep.style.display = 'flex';
			  } else {
				divThirdStep.style.display = 'block';
			  }
			}

			window.addEventListener('resize', adjustDisplay);

			const stepIndicator = document.querySelector('.step-indicator')!;
			stepIndicator.classList.remove('s2', 's1');
			stepIndicator.classList.add('s3');

			// Initial adjustment on page load
			adjustDisplay();
    }

    private async applyImageToMesh(showSuccessDiv : boolean) {
        const canvas = document.getElementById('container-canvas') as HTMLCanvasElement;

        // Find the mesh with name '72_ROCKFON_SCR_D3_ACOUSTICB'
        if (this.activeSide == 'both') {
            this.imagesApplied.both = true;
            this.imagesApplied.front = false;
            this.imagesApplied.back = false;

            this.createPlaneAndProjectImage('front');
            this.createPlaneAndProjectImage('back');
        } else {
            (this.imagesApplied as any)[this.activeSide] = true;
            this.imagesApplied.both = false;

            this.createPlaneAndProjectImage(this.activeSide);
        }

        const buttons = document.querySelectorAll('.upload-image-remove');

        buttons.forEach(button => {
            button.parentNode?.removeChild(button);
        });

        const propertyHtml = document.getElementById('prop-upload-image')!;

        for (const side of ['back', 'front', 'both']) {
            if ((this.imagesApplied as any)[side]) {
                propertyHtml.appendChild(this.createUploadRemoveButton(side));
            }
        }

        this.destroyImageUploadHTML();
        this.coreApp.viewer.requestRenderFrame();

        // Show the "awesome" div for 3 seconds
        if(showSuccessDiv)
        {
            const awesomeDiv = document.querySelector('.awesome') as HTMLDivElement;
            if (awesomeDiv) {
                awesomeDiv.style.display = 'flex';
                setTimeout(() => {
                    awesomeDiv.style.display = 'none';
                }, 8000); // 8000 milliseconds (8 seconds)
            }
        }


        const base64Canvas = canvas.toDataURL("image/jpeg", 2);

        let response = await this.uploadImageToServer('cropped_' + PropertyEditorUI.originalImageName, base64Canvas);

        if(this.activeSide === 'both')
        {
            delete (PropertyEditorUI.croppedImages as any)['front'];
            delete (PropertyEditorUI.croppedImages as any)['back'];
            delete (PropertyEditorUI.originalImages as any)['front'];
            delete (PropertyEditorUI.originalImages as any)['back'];
        }
        else
        {
            delete (PropertyEditorUI.croppedImages as any)['both'];
            delete (PropertyEditorUI.originalImages as any)['both'];
        }

        (PropertyEditorUI.croppedImages as any)[this.activeSide] = response['data']['image_url'];
    }

    private createPlaneAndProjectImage(side : string)
    {
        const canvas = document.getElementById('container-canvas') as HTMLCanvasElement;
        const base64Canvas = canvas.toDataURL("image/jpeg", 2);
        const texture = new DynamicTexture("dynamicTexture", canvas, this.coreApp.scene, false);
        const meshToRemove = this.coreApp.model.layers.getMeshesOnLayer((side === 'back' ? this.backSide : this.frontSide))[0] as AbstractMesh;

        meshToRemove.visibility = 0;

        // Delete all existing planes
        this.deletePlaneByName(side);

        const img = new Image();
        img.src = base64Canvas;
        img.onload = function() {
            if(canvas !== null)
            {
                canvas.getContext('2d')!.drawImage(img, 0, 0);
                texture.update();
            }
        };

        const plane = this.createPlaneFromMesh(meshToRemove, side);

        // Apply the base64 image as a texture to the plane
        const material = new StandardMaterial('planeMaterial', this.coreApp.scene);
        material.specularColor = new Color3(0, 0, 0); // No specular highlights
        material.emissiveColor = new Color3(1, 1, 1); // Fully emissive to be fully lit
        material.diffuseTexture = texture;
        plane.material = material;
    }

    // TypeScript function to reset validations and outputs
private resetValidationsAndOutputs() {
  const listItems = document.querySelectorAll("ul li");

  // Reset classes
  listItems.forEach((item) => {
    item.classList.remove("yes", "no");
  });

  // Reset file type
  document.querySelector(".type")!.classList.remove("yes", "no");

  // Reset file type below the ".type" element
  const fileTypeElement = document.querySelector(".file-type")!;
  fileTypeElement.textContent = '';

  // Reset file resolution
  document.querySelector(".res")!.classList.remove("yes", "no");

  // Reset file size
  const fileSizeElement = document.querySelector(".file-size")!;
  fileSizeElement.textContent = '';

	// Add "hide" class to .uploaded-image
  const uploadedImageElement = document.querySelector(".uploaded-image")!;
  uploadedImageElement.classList.add("hide");

  // Remove "hide" class from .upload-image
  const uploadImageElement = document.querySelector(".upload-image")!;
  uploadImageElement.classList.remove("hide");
  
  // Remove "hide" class from .uploaded-image
  const fileUploadandNext = document.getElementById("file-upload-button")!;
  fileUploadandNext.classList.remove("allowed");
  
  // Reset .or-name text
  const orNameElement = document.querySelector('.or-name')!;
  orNameElement.textContent = 'Supports: JPEG, JPG, PNG or SVG';

  // Reset image resolution
  const imageResolutionElement = document.querySelector(".image-resolution")!;
  imageResolutionElement.textContent = '';
}

// Call this function when you want to reset validations and outputs
private destroyImageUploadHTML() {
  const divUploadImage = document.getElementById('upload-image')!;
  divUploadImage.style.display = 'none';

  const divThirdStep = document.getElementById('upload-step-3')!;
  divThirdStep.style.display = 'none';

  const divFirstStep = document.getElementById('upload-step-1')!;
  divFirstStep.style.display = 'block';

  const divSecondStep = document.getElementById('upload-step-2')!;
  divSecondStep.style.display = 'none';
  
  const stepIndicator = document.querySelector('.step-indicator')!;
  stepIndicator.classList.remove('s2', 's3');
  stepIndicator.classList.add('s1');

  this.resetValidationsAndOutputs(); // Call the reset function here
}

    private deletePlaneByName(side : string, display : boolean = false)
    {
        const scene = this.coreApp.scene;

        scene.meshes.forEach((mesh) => {
            if (mesh instanceof Mesh) {
                if(mesh.name === side)
                {
                    if(display)
                    {
                        const meshToMakeVisible = this.coreApp.model.layers.getMeshesOnLayer((side === 'back' ? this.backSide : this.frontSide))[0] as AbstractMesh;
                        meshToMakeVisible.visibility = 1;
                    }

                    mesh.dispose();
                }
            }
        });
    }

    private createPlaneFromMesh(mesh: AbstractMesh, side : string): Mesh {
        const bbox = mesh.getHierarchyBoundingVectors();

        // Get the dimensions of the bounding box
        const width = bbox.max.x - bbox.min.x;
        const height = bbox.max.y - bbox.min.y;

        // Create a plane with the same size as the mesh
        const plane = MeshBuilder.CreatePlane(
            'planeFromMesh',
            { width: width, height: height },
            this.coreApp.scene
        );

        plane.name = side;
        plane.position.copyFrom(mesh.position);
        plane.position.x = bbox.max.x - (width / 2);
        plane.position.y = bbox.max.y - (height / 2);
        plane.position.z = bbox.min.z; // You can adjust the z position if needed

        plane.scaling.copyFrom(mesh.scaling);

        if((side == 'back' && App.baseArticleNumber !== 'pan') || (side === 'front' && App.baseArticleNumber === 'pan'))
        {
            plane.rotation.y = Math.PI;
            plane.position.z = bbox.max.z;
        }

        return plane;
    }

    /**
     * Gets properties/classes/choices of the current property provider and creates ui for those.
     */
    private async updatePropertyEditor(): Promise<void> {
        try {
            ProgressUI.beginLoading();
            const propertyClasses: Array<PropertyClass> = await this.propertyProvider.getPropertyClasses();
            PropertyEditorUI.properties = await this.propertyProvider.getProperties();
            const htmlPropertyClasses : Array<HTMLElement> = [];
            //let propertyHtml : string = '';

            // create a default class which will be used for properties, which belong to no class
            const defaultClass: PropertyClass = new PropertyClass();
            defaultClass.key = '';
            defaultClass.name = 'Properties';
            propertyClasses.push(defaultClass);


            for(let a = 0; a < propertyClasses.length; a++) {
                const propertiesOfClass: Array<Property> = PropertyEditorUI.properties.filter((property) => {
                    // if the property has no class, assign "" so it gets the default class we created before
                    const className: string = property.class != null ? property.class : '';
                    return className === propertyClasses[a].key;
                });

                if (propertiesOfClass.length > 0) {
                    const htmlPropertyClass: HTMLElement = this.createPropertyClass(propertyClasses[a]);

                    let index = 0;

                    for(let i = 0; i < propertiesOfClass.length; i++) {
                        htmlPropertyClass.appendChild(await this.createPropertyItem(propertiesOfClass[i], index));

                        index++;
                    }

                    htmlPropertyClasses.push(htmlPropertyClass);
                }
            }

            this.htmlContainer.innerHTML = '';

            htmlPropertyClasses.forEach((htmlPropertyClass: HTMLElement) => {
                //propertyHtml += htmlPropertyClass.innerHTML;

                this.htmlContainer.append(htmlPropertyClass);
            });

            ProgressUI.endLoading();
        } catch (e) {
            console.error('failed to get properties', e);
            this.htmlContainer.innerText = 'Migration of article might be required.';
        }
    }

private createUploadImagePropertyItem(): HTMLElement {
    const propertyHtmlContainer: HTMLDivElement = document.createElement('div');
    let propertyHtml: HTMLDivElement = document.createElement('div');

    propertyHtml.id = 'prop-upload-image';
    propertyHtml.className = 'property-choices image-upload';

    // Define translations for each language
    const translations: Record<string, Record<string, string>> = {
        en: {
            title: 'Add a custom graphic to your Canva:',
            subtitle: 'Do it',
        },
        pl: {
            title: 'Dodaj niestandardową grafikę do swojego Canva:',
            subtitle: 'Zrób to',
        },
        de: {
            title: 'Fügen Sie eine benutzerdefinierte Grafik zu Ihrem Canva hinzu:',
            subtitle: 'Tu es',
        },
        cs: {
            title: 'Přidejte vlastní grafiku do svého Canva:',
            subtitle: 'Udělejte to',
        },
        fr: {
            title: 'Ajoutez une image personnalisée à votre Canva:',
            subtitle: 'Faites-le',
        },
        es: {
            title: 'Agrega un gráfico personalizado a tu Canva:',
            subtitle: 'Hazlo',
        },
    };

    // Default language is English
    let currentLanguage = 'en';

    // Try to get language parameter from the URL
    const languageFromURL = this.getURLParameter('language');
    if (languageFromURL) {
        // Use language from URL if available
        currentLanguage = languageFromURL;
    }

    // Choose the appropriate translation based on the desired language
    const currentTranslations = translations[currentLanguage] || translations['en'];

    propertyHtml.innerHTML = `<div class="property-header img-upl">
        <div class="property-title">${currentTranslations.title}</div>
        <div class="property-subtitle">${currentTranslations.subtitle}</div>
    </div>`;

    propertyHtml.appendChild(this.createPropertyModal());

    for (const side of ['back', 'front', 'both']) {
        if ((this.imagesApplied as any)[side]) {
            propertyHtml.appendChild(this.createUploadRemoveButton(side));
        }
    }

    propertyHtmlContainer.className = 'property ppr-img';

    // save information in dataset css
    propertyHtmlContainer.dataset.editable = 'true';
    propertyHtmlContainer.dataset.visible = 'true';
    propertyHtmlContainer.dataset.choiceList = 'false';

    propertyHtmlContainer.append(propertyHtml);

    return propertyHtmlContainer;
}

// Function to get URL parameter by name
private getURLParameter(name: string): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}



    /**
     * Property classes are like a category for properties.
     */
    private createPropertyClass(propertyClass: PropertyClass): HTMLElement {
        const propClassHtml: HTMLDivElement = document.createElement('div');
        if (propertyClass.name != null) {
            propClassHtml.innerText = '';
        }
        propClassHtml.className = 'property-class';
        return propClassHtml;
    }

    private async createPropertyItem(property: Property, index: number): Promise<HTMLElement> {
        const propertyHtmlContainer: HTMLDivElement = document.createElement('div');
        let propertyHtml : HTMLDivElement = document.createElement('div');
        const propertyValue: PropertyValue | null = property.getValue();

        propertyHtml.id = 'prop-' + index.toString();
        propertyHtml.className = 'property-choices ' + property.key.replace('[Character]', '').replace('[Numeric]', '');

        if(property.key === '[Character]ROCKFON_SCR_Screen_Color' && this.allColorsAreShown)
        {
            propertyHtml.className += ' showme';
        }

        propertyHtml.innerHTML = '<div class="property-header">' +
            '<div class="property-title">' + property.getName() + ':</div>' +
            '<div class="property-subtitle">' + (propertyValue?.text ?? '') + '</div>' +
            '</div>';

        propertyHtml = await this.handlePropertyChoices(property, propertyHtml, index);

        propertyHtmlContainer.className = 'property ppr-' + property.key.replace('[Character]', '').replace('[Numeric]', '') + '';

        // save information in dataset css
        propertyHtmlContainer.dataset.editable = property.editable ? 'true' : 'false';
        propertyHtmlContainer.dataset.visible = property.visible ? 'true' : 'false';
        propertyHtmlContainer.dataset.choiceList = property.choiceList ? 'true' : 'false';

        propertyHtmlContainer.append(propertyHtml);

        return propertyHtmlContainer;
    }

    /**
     * If user clicks a property we show the possible choices or let the user directly input a value (depending on the property type).
     * We store the current clicked property in activeProperty.
     * If it will be clicked again, we remove the choices.
     */
    private async handlePropertyChoices(property: Property, propertyHtml : HTMLDivElement, index : number): Promise<HTMLDivElement> {
        if (property.choiceList) {
            if(!this.initialRun) {
                const propertyChoices: Array<PropertyValue> | null = await property.getChoices();
                if (propertyChoices != null) {
                    if(propertyChoices[0].value == 'y' || propertyChoices[0].value == 'n') {
                        // yes no toggle
                        propertyHtml.appendChild(this.createPropertyToggle(property, propertyChoices, index));
                    } else {
                        propertyChoices.forEach((propertyValue: PropertyValue) => {
                            propertyHtml.appendChild(this.createPropertyChoice(property, propertyValue));
                        });
                    }
                }
            }
        } else {
            propertyHtml.append(this.createPropertyInput(property));
        }

        return propertyHtml;
    }

private createPropertyInput(property: Property): HTMLElement {
    const propertyChoice: HTMLDivElement = document.createElement('div');
    propertyChoice.className = 'property-choice selected';

    /* Custom size input fields */

    propertyChoice.onclick = async () => {
        const parentElement = propertyChoice.parentNode as HTMLElement | null;

        if (parentElement) {
            const existingInputField = parentElement.querySelector("input[type='text']") as HTMLInputElement | null;

            if (existingInputField) {
                existingInputField.focus();
                const defaultValue = existingInputField.value;
                const trimmedDefaultValue = defaultValue.replace(/\.$/, "");
                existingInputField.value = trimmedDefaultValue;
                return;
            }

            const inputContainer = document.createElement("div");
            inputContainer.classList.add("custom-size-input");

            const inputField = document.createElement("input");
            const confirmButton = document.createElement("button");
            const cancelButton = document.createElement("button");

            inputField.type = "text";
            const defaultValueElement = parentElement.querySelector(".property-choice-text-wrap");
            const defaultValue = defaultValueElement ? defaultValueElement.textContent || "" : "";
            inputField.value = defaultValue.replace(/\.$/, "");

            // Choose the appropriate translation based on the desired language
            const currentTranslations = this.getTranslations();

            confirmButton.innerText = currentTranslations.confirm || "Confirm";
            cancelButton.innerText = currentTranslations.cancel || "Cancel";

            inputContainer.appendChild(inputField);
            inputContainer.appendChild(confirmButton);
            inputContainer.appendChild(cancelButton);

            parentElement.classList.add("disa");

            parentElement.insertBefore(inputContainer, propertyChoice.nextSibling);

            const handleConfirm = async () => {
                const userInput = inputField.value;

                if (userInput !== "") {
                    ProgressUI.beginLoading();
                    await property.setValue(userInput);
                    this.coreApp.viewer.view.cameraControl.setFixedTarget(this.viewerUI.getCenterOfSceneElement());
                    ProgressUI.endLoading();

                    if (App.baseArticleNumber === 'hdi') {
                        this.recenterMeshesOfHdiOnCustomSizes();
                    }

                    if (this.viewerUI.dimensionsAreVisible) {
                        this.viewerUI.renderDimensionsOfBoundingBoxOnDimensionsChanged();
                    }
                }

                parentElement.classList.remove("disa");
                inputContainer.remove();
            };

            confirmButton.onclick = handleConfirm;

            cancelButton.onclick = () => {
                parentElement.classList.remove("disa");
                inputContainer.remove();
            };

            inputField.focus();

            inputField.addEventListener("input", () => {
                const inputValue = inputField.value;
                inputField.value = inputValue.replace(/[^0-9]/g, "");
            });

            inputField.addEventListener("keydown", (event) => {
                if (event.key === "Enter") {
                    event.preventDefault();
                    handleConfirm();
                }
            });
        }
    };

    const label: HTMLDivElement = document.createElement('div');
    label.innerHTML = '<div class="property-choice-text-wrap">' + property.getValue()?.value + '</div>';
    propertyChoice.appendChild(label);

    return propertyChoice;
}

// Function to get translations based on the current language
private getTranslations(): Record<string, string> {
    const translations: Record<string, Record<string, string>> = {
        en: {
            confirm: 'Confirm',
            cancel: 'Cancel',
        },
        pl: {
            confirm: 'Potwierdź',
            cancel: 'Anuluj',
        },
        de: {
            confirm: 'Bestätigen',
            cancel: 'Abbrechen',
        },
        cs: {
            confirm: 'Potvrdit',
            cancel: 'Zrušit',
        },
        fr: {
            confirm: 'Confirmer',
            cancel: 'Annuler',
        },
        es: {
            confirm: 'Confirmar',
            cancel: 'Cancelar',
        },
    };

    // Default language is English
    let currentLanguage = 'en';

    // Try to get language parameter from the URL
    const languageFromURL = this.getURLParameter('language');
    if (languageFromURL) {
        // Use language from URL if available
        currentLanguage = languageFromURL;
    }

    // Choose the appropriate translation based on the desired language
    return translations[currentLanguage] || translations['en'];
}

	
	
	
	

    private recenterMeshesOfHdiOnCustomSizes() : void
    {
        // Reference mesh (72_ROCKFON_SCR_D3_ROD)
        const referenceMesh = this.coreApp.model.layers.getMeshesOnLayer('72_ROCKFON_SCR_D3_ROD')[0] as AbstractMesh;

        // Calculate the Y position of the middle point of the reference mesh
        const referenceMiddleY = referenceMesh.getBoundingInfo().boundingBox.centerWorld.y;

        const allMeshes = this.coreApp.scene.meshes;

        for (let i = 0; i < allMeshes.length; i++) {
            const mesh = allMeshes[i];

            if(mesh)
            {
                if(mesh.name !== 'gfj_mesh_72_ROCKFON_SCR_D3_ROD')
                {
                    const meshMiddleY = mesh.getBoundingInfo().boundingBox.centerWorld.y;
                    const yOffsetDiff = referenceMiddleY - meshMiddleY;
                    mesh.position.y += yOffsetDiff;
                }
            }
        }
    }

    private createUploadRemoveButton(side: string): HTMLElement {
        const propertyButton: HTMLButtonElement = document.createElement('button');
        propertyButton.innerHTML = '<div data-side="' + side + '" class="pre">' + side + '</div> <div class="post">Delete ' + side + '</div>';

        propertyButton.onclick = async () => {
            ProgressUI.beginLoading();

            if(side === 'both')
            {
                this.deletePlaneByName('front', true);
                this.deletePlaneByName('back', true);

                delete (PropertyEditorUI.croppedImages as any)['both'];
                delete (PropertyEditorUI.originalImages as any)['both'];

                this.imagesApplied.both = false;
            }
            else
            {
                this.deletePlaneByName(side, true);

                delete (PropertyEditorUI.croppedImages as any)[side];
                delete (PropertyEditorUI.originalImages as any)[side];

                (this.imagesApplied as any)[side] = false;
            }

            this.coreApp.viewer.requestRenderFrame();

            ProgressUI.endLoading();

            propertyButton.remove();
        };

        propertyButton.className = 'property-choice upload-image-remove';

        // Adding event listener to the existing close button
        const closeButtons: NodeListOf<HTMLElement> = document.querySelectorAll('.close-img-upload');

        closeButtons.forEach((closeButton) => {
            closeButton.addEventListener('click', () => {
                const uploadImageHTMLElement = document.getElementById('upload-image') as HTMLElement;
                uploadImageHTMLElement.classList.remove('open'); // Remove the 'open' class

                this.destroyImageUploadHTML();
                this.resetValidationsAndOutputs();
            });
        });

        return propertyButton;
    }

private createPropertyModal(): HTMLElement {
    const propertyButton: HTMLButtonElement = document.createElement('button');

    // Function to get URL parameter by name
    const getURLParameter = (name: string): string | null => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    };

    // Default language is English
    let currentLanguage = 'en';

    // Try to get language parameter from the URL
    const languageFromURL = getURLParameter('language');
    if (languageFromURL) {
        // Use language from URL if available
        currentLanguage = languageFromURL;
    }

    // Define translations for each language
    const translations: Record<string, string> = {
        en: '<div class="pre">Upload image (Beta)</div> <div class="post">Click to upload a custom image</div>',
        pl: '<div class="pre">Prześlij obraz (Beta)</div> <div class="post">Kliknij, aby przesłać niestandardowy obraz</div>',
        de: '<div class="pre">Bild hochladen (Beta)</div> <div class="post">Klicken Sie hier, um ein benutzerdefiniertes Bild hochzuladen</div>',
        cs: '<div class="pre">Nahrát obrázek (Beta)</div> <div class="post">Klepněte pro nahrání vlastního obrázku</div>',
        fr: '<div class="pre">Télécharger l\'image (bêta)</div> <div class="post">Cliquez pour télécharger une image personnalisée</div>',
        es: '<div class="pre">Subir imagen (Beta)</div> <div class="post">Haz clic para subir una imagen personalizada</div>',
    };

    // Choose the appropriate translation based on the desired language
    const currentLanguageText = translations[currentLanguage] || translations['en'];

    propertyButton.innerHTML = currentLanguageText;

    propertyButton.onclick = async () => {
        ProgressUI.beginLoading();

        const uploadImageHTMLElement = document.getElementById('upload-image')!;
        uploadImageHTMLElement.style.display = 'block';
        uploadImageHTMLElement.classList.add('open'); // Add the 'open' class

        ProgressUI.endLoading();
    };

    propertyButton.className = 'property-choice';

    // Adding event listener to the existing close button
    const closeButtons: NodeListOf<HTMLElement> = document.querySelectorAll('.close-img-upload');

    closeButtons.forEach((closeButton) => {
        closeButton.addEventListener('click', () => {
            const uploadImageHTMLElement = document.getElementById('upload-image') as HTMLElement;
            uploadImageHTMLElement.classList.remove('open'); // Remove the 'open' class

            // Assuming this.destroyImageUploadHTML and this.resetValidationsAndOutputs are methods of your class
            this.destroyImageUploadHTML(); // Call your destroyImageUploadHTML() function
            this.resetValidationsAndOutputs(); // Call the reset function here
        });
    });

    return propertyButton;
}




    // New toggle
    private createPropertyToggle(
        property: Property,
        propertyChoices: Array<PropertyValue>,
        index : number
    ): HTMLElement {
        const propertyChoice: HTMLInputElement = document.createElement('input');

        propertyChoice.onclick = async () => {
            ProgressUI.beginLoading();

            if(property.getValue()?.value === 'y') {
                await property.setValue("n");
            } else {
                await property.setValue("y");
            }

            ProgressUI.endLoading();
        };

        propertyChoice.className = 'property-choice-toggle';
        propertyChoice.setAttribute('type', 'checkbox');

        if(property.getValue()?.value === 'y') {
            propertyChoice.checked = true;
        } else {
            propertyChoice.checked = false;
        }

        return propertyChoice;
    }

    private async applyAllImages()
    {
        if(this.imagesApplied.front)
        {
            this.activeSide = 'front';
            setSide('front');

            reDraw(this.fetchRGBFromBackground(this.canvasBackground));
            await this.applyImageToMesh(false);
        }

        if(this.imagesApplied.back)
        {
            this.activeSide = 'back';
            setSide('back');

            reDraw(this.fetchRGBFromBackground(this.canvasBackground));
            await this.applyImageToMesh(false);
        }

        if(this.imagesApplied.both)
        {
            this.activeSide = 'both';
            setSide('both');

            reDraw(this.fetchRGBFromBackground(this.canvasBackground));
            await this.applyImageToMesh(false);
        }
    }

    private createPropertyChoice(
        property: Property,
        propertyValue: PropertyValue,
    ): HTMLElement {
        const propertyChoice: HTMLDivElement = document.createElement('div');
        propertyChoice.className = 'property-choice' + (property.getValue()?.value === propertyValue.value ? ' selected' : '');
		
        propertyChoice.onclick = async () => {
            ProgressUI.beginLoading();

            // If hub color is changed, save to memory to reapply later
            if(property.key === '[Character]ROCKFON_HUB_Panel_Color')
            {
                this.hubColorProperty = property;
                this.hubColorPropertyValue = propertyValue;
            }

            if(property.key !== '[Character]ROCKFON_SCR_Frame_Color' && property.key !== '[Character]ROCKFON_SCR_Screen_Color')
            {
                this.deleteImagePlanes();
            }

            if(property.key === '[Character]ROCKFON_SCR_Screen_Color')
            {
                const computedStyle = window.getComputedStyle(propertyChoice, '::after');
                this.canvasBackground = computedStyle.background;

                if(this.imagesApplied.both || this.imagesApplied.front || this.imagesApplied.back)
                {
                    await this.applyAllImages();
                }
            }

            await property.setValue(propertyValue.value);

            if(this.dynamicDimensionsAreRendered)
            {
                this.recenterMeshesOfHdiOnCustomSizes();
            }

            if(property.key === '[Character]ROCKFON_SCR_DIM_Typ')
            {
                this.dynamicDimensionsAreRendered = (propertyValue.value === 'TYP_i');

                // User switched to custom dimensions, recenter
                if(propertyValue.value === 'TYP_i')
                {
                    this.recenterMeshesOfHdiOnCustomSizes();
                }
            }

            if(this.camereResetableProperties.includes(property.key)) {
                // User just updated a property that requires us to reset the fixed camera target
                this.coreApp.viewer.view.cameraControl.setFixedTarget(this.viewerUI.getCenterOfSceneElement());

                // And also make sure that the dimensions are rerendered
                if(this.viewerUI.dimensionsAreVisible) {
                    this.viewerUI.renderDimensionsOfBoundingBoxOnDimensionsChanged();
                }

                // If the base article is hub, reapply the color
                if(App.baseArticleNumber === 'hub')
                {
                    await this.hubColorProperty.setValue(this.hubColorPropertyValue.value);
                }
            }

            ProgressUI.endLoading();
        };
        if (!isNullOrEmpty(propertyValue.largeIcon)) {
            const icon: HTMLImageElement = document.createElement('img');
            icon.src = propertyValue.largeIcon;
            propertyChoice.appendChild(icon);
        }
       /* if (!isNullOrEmpty(propertyValue.text)) {
            const label: HTMLDivElement = document.createElement('div');
            const splittedPropertyChoiceText = propertyValue.text.split(' - ');

            if(propertyValue.text == splittedPropertyChoiceText[0])
                label.innerHTML = '<div class="property-choice-text-wrap">' + splittedPropertyChoiceText[0] + '</div>';
            else
                label.innerHTML = '<div class="property-choice-text-wrap">' + splittedPropertyChoiceText[0] + '</div> <div class="split-copy">' + splittedPropertyChoiceText[1] + '</div>';

            propertyChoice.appendChild(label);
        }*/
	
		if (!isNullOrEmpty(propertyValue.text)) {
		  const label: HTMLDivElement = document.createElement('div');
		  const splittedPropertyChoiceText = propertyValue.text.split(' - ');
		  const regex = /\(([^)]+)\)/g; // regular expression to match parentheses and their contents

		  // loop through each substring in splittedPropertyChoiceText and remove any parentheses and their contents
		  const textWithoutParentheses = splittedPropertyChoiceText.map(text => text.replace(regex, ''));

		  // Look for a dot in textWithoutParentheses[0] and wrap it in <wrap> if found
		  let formattedText = textWithoutParentheses[0];
		  if (formattedText.includes('.')) {
			formattedText = formattedText.replace('.', '<wrap class="dot">.</wrap>');
		  }

		  if (textWithoutParentheses[0] === propertyValue.text)
			label.innerHTML = '<div class="property-choice-text-wrap">' + formattedText + '</div>';
		  else
			label.innerHTML = '<div class="property-choice-text-wrap">' + formattedText + '</div> <div class="split-copy">' + textWithoutParentheses[1] + '</div>';

		  propertyChoice.appendChild(label);
		}
        
        return propertyChoice;
    }

    private deleteImagePlanes() : void
    {
        this.deletePlaneByName('front');
        this.deletePlaneByName('back');

        this.imagesApplied = new ImagesAppliedDto(); // reset all to false
    }

    private fetchRGBFromBackground(background: string) : string
    {
        if(background !== '' && background !== undefined)
        {
            const rgbPattern = /rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/;
            const match = background.match(rgbPattern);

            if (match) {
                const r = match[1];
                const g = match[2];
                const b = match[3];

                // Return the RGB values as a string
                return `rgb(${r}, ${g}, ${b})`;
            }
        }

        return '#DFE5D7';
    }
}












let side;

const canvasStates = {
    front: createCanvasSideState(),
    back: createCanvasSideState(),
    both: createCanvasSideState()
};

function createCanvasSideState() {
    return {
        img: null,
        canvas: document.getElementById("container-canvas"),
        offsetX: $("#container-canvas").offset()?.left || 0,
        offsetY: $("#container-canvas").offset()?.top || 0,
        ctx: null,
        startX: 0,
        startY: 0,
        isDown: false,
        pi2: Math.PI * 2,
        originalImageWidth: 0,
        originalImageHeight: 0,
        imageX: 50,
        imageY: 50,
        imageWidth: 0,
        imageHeight: 0,
        imageRight: 0,
        imageBottom: 0,
        draggingImage: false,
    };
}

export function setSide(newSide) {
    side = newSide;
}

export function reDraw(pBackgroundColor) {
    // clear the canvas
    canvasStates[side].ctx.clearRect(0, 0, canvasStates[side].canvas.width, canvasStates[side].canvas.height);
    canvasStates[side].ctx.fillStyle = pBackgroundColor;
    canvasStates[side].ctx.fillRect(0, 0, canvasStates[side].canvas.width, canvasStates[side].canvas.height);

    // draw the image
    canvasStates[side].ctx.drawImage(canvasStates[side].img, 0, 0, canvasStates[side].img.width, canvasStates[side].img.height, canvasStates[side].imageX, canvasStates[side].imageY, canvasStates[side].imageWidth, canvasStates[side].imageHeight);
}

export function loadVanillaJsForDragDropResize(pImageWidth, pImageHeight, pBase64, pBackgroundColor)
{
    canvasStates[side].canvas = document.getElementById("container-canvas");
    canvasStates[side].ctx = canvasStates[side].canvas.getContext("2d");

    canvasStates[side].canvasOffset = $("#container-canvas").offset();
    canvasStates[side].offsetX = canvasStates[side].canvasOffset.left;
    canvasStates[side].offsetY = canvasStates[side].canvasOffset.top;

    canvasStates[side].startX;
    canvasStates[side].startY;
    canvasStates[side].isDown = false;

    canvasStates[side].pi2 = Math.PI * 2;
    canvasStates[side].originalImageWidth, canvasStates[side].originalImageHeight; // Store the original image dimensions
    canvasStates[side].imageX = 50;
    canvasStates[side].imageY = 50;
    canvasStates[side].imageWidth, canvasStates[side].imageHeight, canvasStates[side].imageRight, canvasStates[side].imageBottom;
    canvasStates[side].draggingImage = false;

// Define the hitCanvas function to enable dragging of the canvas
    function hitCanvas(x, y) {
        return true; // Always return true to enable dragging on the canvas
    }

    canvasStates[side].img = new Image();
    canvasStates[side].img.onload = function () {
        canvasStates[side].originalImageWidth = canvasStates[side].img.width; // Store the original image width
        canvasStates[side].originalImageHeight = canvasStates[side].img.height; // Store the original image height
        canvasStates[side].imageWidth = pImageWidth;
        canvasStates[side].imageHeight = pImageHeight;
        canvasStates[side].imageRight = canvasStates[side].imageX + canvasStates[side].imageWidth;
        canvasStates[side].imageBottom = canvasStates[side].imageY + canvasStates[side].imageHeight;
        draw(false, false);

        // Attach event listeners after the image has loaded
        $("#container-canvas").mousedown(function (e) {
            handleMouseDown(e);
        });
        $("#container-canvas").mousemove(function (e) {
            handleMouseMove(e);
        });
        $("#container-canvas").mouseup(function (e) {
            handleMouseUp(e);
        });
        $("#container-canvas").mouseout(function (e) {
            handleMouseOut(e);
        });
    }

    canvasStates[side].img.src = pBase64;

    function draw(withAnchors, withBorders) {
        // clear the canvas
        canvasStates[side].ctx.clearRect(0, 0, canvasStates[side].canvas.width, canvasStates[side].canvas.height);
        canvasStates[side].ctx.fillStyle = pBackgroundColor;
        canvasStates[side].ctx.fillRect(0, 0, canvasStates[side].canvas.width, canvasStates[side].canvas.height);

        // draw the image
        canvasStates[side].ctx.drawImage(canvasStates[side].img, 0, 0, canvasStates[side].img.width, canvasStates[side].img.height, canvasStates[side].imageX, canvasStates[side].imageY, canvasStates[side].imageWidth, canvasStates[side].imageHeight);

        // Optionally draw the connecting anchor lines or draggable anchors (removed in this case)
    }

    function handleMouseDown(e) {
        canvasStates[side].startX = parseInt(e.clientX - canvasStates[side].offsetX);
        canvasStates[side].startY = parseInt(e.clientY - canvasStates[side].offsetY);

        // Check if the click is inside the canvas
        if (hitCanvas(canvasStates[side].startX, canvasStates[side].startY)) {
            canvasStates[side].isDown = true;
        }
    }

    function handleMouseUp(e) {
        canvasStates[side].isDown = false;
    }

    function handleMouseOut(e) {
        canvasStates[side].isDown = false;
    }

    function handleMouseMove(e) {
        if (canvasStates[side].isDown) {
            canvasStates[side].mouseX = parseInt(e.clientX - canvasStates[side].offsetX);
            canvasStates[side].mouseY = parseInt(e.clientY - canvasStates[side].offsetY);

            // Calculate the distance moved
            var dx = canvasStates[side].mouseX - canvasStates[side].startX;
            var dy = canvasStates[side].mouseY - canvasStates[side].startY;

            // Update the image and canvas position
            canvasStates[side].imageX += dx;
            canvasStates[side].imageY += dy;

            // Redraw the image with the new position
            draw(false, false);

            // Update the start position
            canvasStates[side].startX = canvasStates[side].mouseX;
            canvasStates[side].startY = canvasStates[side].mouseY;
        }
    }

    $("#container-canvas").mousedown(function (e) {
        handleMouseDown(e);
    });
    $("#container-canvas").mousemove(function (e) {
        handleMouseMove(e);
    });
    $("#container-canvas").mouseup(function (e) {
        handleMouseUp(e);
    });
    $("#container-canvas").mouseout(function (e) {
        handleMouseOut(e);
    });

    // Function to set the image height while maintaining aspect ratio
    document.getElementById('setHeightButton').addEventListener('click', function () {
        var newHeight = canvasStates[side].canvas.height; // Set the image height to the canvas height
        var aspectRatio = canvasStates[side].img.width / canvasStates[side].img.height;
        var newWidth = newHeight * aspectRatio;

        // Center the image on the canvas
        canvasStates[side].imageX = (canvasStates[side].canvas.width - newWidth) / 2;
        canvasStates[side].imageY = (canvasStates[side].canvas.height - newHeight) / 2;

        canvasStates[side].imageWidth = newWidth;
        canvasStates[side].imageHeight = newHeight;

        draw(false, false);
    });

    // Function to set the image width while maintaining aspect ratio
    document.getElementById('setWidthButton').addEventListener('click', function () {
        var newWidth = canvasStates[side].canvas.width; // Set the image width to the canvas width
        var aspectRatio = canvasStates[side].img.height / canvasStates[side].img.width;
        var newHeight = newWidth * aspectRatio;

        // Center the image on the canvas
        canvasStates[side].imageX = (canvasStates[side].canvas.width - newWidth) / 2;
        canvasStates[side].imageY = (canvasStates[side].canvas.height - newHeight) / 2;

        canvasStates[side].imageWidth = newWidth;
        canvasStates[side].imageHeight = newHeight;

        draw(false, false);
    });

    // align custom code thomas
    // Function to align the image left on the canvas
    function alignLeft() {
        canvasStates[side].imageX = 0;
        canvasStates[side].imageRight = canvasStates[side].imageWidth;
        draw(false, false);
    }

    // Function to align the image center on the canvas
    function alignCenter() {
        canvasStates[side].imageX = (canvasStates[side].canvas.width - canvasStates[side].imageWidth) / 2;
        canvasStates[side].imageRight = canvasStates[side].imageX + canvasStates[side].imageWidth;
        draw(false, false);
    }

    // Function to align the image right on the canvas
    function alignRight() {
        canvasStates[side].imageX = canvasStates[side].canvas.width - canvasStates[side].imageWidth;
        canvasStates[side].imageRight = canvasStates[side].canvas.width;
        draw(false, false);
    }

    // Event listener for the Align Left button
    document.getElementById('alignLeftButton').addEventListener('click', alignLeft);

    // Event listener for the Align Center button
    document.getElementById('alignCenterButton').addEventListener('click', alignCenter);

    // Event listener for the Align Right button
    document.getElementById('alignRightButton').addEventListener('click', alignRight);

    // einde custom align code thomas

    // thomas align vertical code
    // Function to align the image to the top of the canvas
    function alignTop() {
        canvasStates[side].imageY = 0;
        canvasStates[side].imageBottom = canvasStates[side].imageHeight;
        draw(false, false);
    }

    // Function to align the image to the vertical center of the canvas
    function alignCenterVertical() {
        canvasStates[side].imageY = (canvasStates[side].canvas.height - canvasStates[side].imageHeight) / 2;
        canvasStates[side].imageBottom = canvasStates[side].imageY + canvasStates[side].imageHeight;
        draw(false, false);
    }

    // Function to align the image to the bottom of the canvas
    function alignBottom() {
        canvasStates[side].imageY = canvasStates[side].canvas.height - canvasStates[side].imageHeight;
        canvasStates[side].imageBottom = canvasStates[side].canvas.height;
        draw(false, false);
    }

    // Event listener for the Align Top button
    document.getElementById('alignTopButton').addEventListener('click', alignTop);

    // Event listener for the Align Center Vertical button
    document.getElementById('alignCenterVerticalButton').addEventListener('click', alignCenterVertical);

    // Event listener for the Align Bottom button
    document.getElementById('alignBottomButton').addEventListener('click', alignBottom);

    // einde align vertical code

    // Scale custom code
    var currentScale = 1; // Default scale

    // Function to scale the image on the canvas
    function scaleImage(scale) {
        var centerX = canvasStates[side].imageX + canvasStates[side].imageWidth / 2;
        var centerY = canvasStates[side].imageY + canvasStates[side].imageHeight / 2;

        currentScale = scale;
        canvasStates[side].imageWidth = canvasStates[side].originalImageWidth * scale; // Use the original image dimensions
        canvasStates[side].imageHeight = canvasStates[side].originalImageHeight * scale; // Use the original image dimensions

        // Update imageX and imageY to keep the center of the image fixed
        canvasStates[side].imageX = centerX - canvasStates[side].imageWidth / 2;
        canvasStates[side].imageY = centerY - canvasStates[side].imageHeight / 2;

        canvasStates[side].imageRight = canvasStates[side].imageX + canvasStates[side].imageWidth;
        canvasStates[side].imageBottom = canvasStates[side].imageY + canvasStates[side].imageHeight;
        draw(false, false);
    }

    // Event listener for the scale slider
    document.getElementById('scaleSlider').addEventListener('input', function (e) {
        var scaleValue = parseFloat(e.target.value);
        scaleImage(scaleValue);
    });
}
export class ImagesAppliedDto {
    front: boolean;
    back: boolean;
    both: boolean;

    constructor(front: boolean = false, back: boolean = false, both: boolean = false) {
        this.front = front;
        this.back = back;
        this.both = both;
    }
}
import * as cf from '@egr/wcf/modules/cf';
import * as basket from '@egr/wcf/modules/eaiws/basket';
import { Viewer } from '@egr/wcf/modules/core/view/Viewer';
import { isNullOrEmpty } from '@egr/wcf/modules/utils/string';
import { Log } from '@egr/wcf/modules/utils';

import { ProgressUI } from '../progress';

import './index.css';
import {PropertyEditorUI, PropertyEditorUI as propertyEditor} from "../property-editor";
import {PropertyDto} from "../../dto/PropertyDto";
import {App} from "../../index";
import {Application} from "@egr/wcf/modules/core";
import {Engine, FreeCamera, Tools} from "@babylonjs/core";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {showDownloadsDialog, showRequestAQuoteDialog} from "../../@types/misc/misc";
import {PropertyImageDto} from "../../dto/PropertyImageDto";

/**
 * Creates export data like screenshots or 3D files (DWG, 3DS,..).
 */
export class ExportUI {
    private engine : Engine;
    private coreApp : Application;
    private articleManager : cf.ArticleManager;

    constructor(htmlContainer: HTMLElement, articleManager: cf.ArticleManager, viewer: Viewer, engine: Engine, coreApp: Application) {
        this.engine = engine;
        this.coreApp = coreApp;
        this.articleManager = articleManager;




		//TRANSLATIONS
		
		
function getParameterByName(name: string, url?: string): string | null {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// Get language from the URL, default to 'en' if not present
const language: string = getParameterByName('language') || 'en';

function translateButton(label: string, language: string): string {
    const translations: { [key: string]: { [key: string]: string } } = {
		'pl': {
				'Request a quote': 'Zapytaj o ofertę',
				'PDF Spec sheet': 'Arkusz specyfikacji PDF',
				'JPG Image': 'Obraz JPG',
				'3D CAD file': 'Plik CAD 3D',
				'Sharing link': 'Link do udostępniania'
			},
		'de': {
            'Request a quote': 'Angebot anfordern',
            'PDF Spec sheet': 'PDF-Datenblatt',
            'JPG Image': 'JPG-Bild',
            '3D CAD file': '3D-CAD-Datei',
			'Sharing link': 'Link teilen'
        },
        'cs': {
            'Request a quote': 'Požádat o cenovou nabídku',
            'PDF Spec sheet': 'Specifikační list PDF',
            'JPG Image': 'Obrázek JPG',
            '3D CAD file': '3D CAD soubor',
			'Sharing link': 'Sdílení odkazu'
        },
		'fr': {
            'Request a quote': 'Demande de devis',
            'PDF Spec sheet': 'Fiche technique PDF',
            'JPG Image': 'Image JPG',
            '3D CAD file': 'Fichier CAO 3D',
			'Sharing link': 'Partager le lien'
        },
        'es': {
            'Request a quote': 'Solicitar presupuesto',
            'PDF Spec sheet': 'Hoja de especificaciones en PDF',
            'JPG Image': 'Imagen JPG',
            '3D CAD file': 'Archivo 3D CAD',
			'Sharing link': 'Compartir enlace'
        }
        
    };

    const languageTranslations = translations[language];

    if (languageTranslations) {
        const translatedLabel = languageTranslations[label];
        return translatedLabel || label;
    } else {
        // If the specified language is not found, return the original label
        return label;
    }
}



        // @ts-ignore
			let requestAQuoteButton: HTMLButtonElement = this.createButton(translateButton('Request a quote', language));
			requestAQuoteButton.id = 'request-quote-button';
			requestAQuoteButton.onclick = this.onShareAQuoteButtonClicked.bind(this);

			htmlContainer.appendChild(this.createButton(translateButton('PDF Spec sheet', language), this.onPDFClicked.bind(this, articleManager, 'DXF')));
			htmlContainer.appendChild(this.createButton(translateButton('JPG Image', language), this.onScreenshotClicked.bind(this, viewer)));

			const button: HTMLButtonElement = document.createElement('button');
			button.className = 'export-item';
			button.innerText = translateButton('3D CAD file', language);
			button.onclick = showDownloadsDialog;



        htmlContainer.appendChild(button);
		htmlContainer.appendChild(requestAQuoteButton);

        var downloadsButtonsContainer = document.getElementById('downloads-buttons')!;
        downloadsButtonsContainer.appendChild(this.createButton(App.articleTitle + ' - Autocad (DWG)', this.onDownloadClicked.bind(this, articleManager, 'DWG')));
        downloadsButtonsContainer.appendChild(this.createButton(App.articleTitle + ' - 3ds Max (ZIP)', this.onDownloadClicked.bind(this, articleManager, '3DS')));

        // title
        document.getElementById('downloads-title')!.innerHTML = App.articleTitle!;
    }
	
    private createButton(text: string, onClick: () => Promise<void>): HTMLButtonElement {
        const button: HTMLButtonElement = document.createElement('button');
        button.className = 'export-item';
        button.innerText = text;
        button.onclick = onClick;
        return button;
    }

    private async onShareAQuoteButtonClicked() {
        ProgressUI.beginLoading();

        let propertiesDto = await this.buildPropertiesDto();

        if(propertiesDto.length > 0)
        {
            let response = await this.createAndDownloadPdfFile(propertiesDto);
            const miscRFNField8: HTMLInputElement | null = document.querySelector('input[name="miscRFNField8"]');

            if (miscRFNField8) {
                // @ts-ignore
                miscRFNField8.value = response['data']['pdf_url'];
            }
        }

        const miscRFNField9: HTMLInputElement | null = document.querySelector('input[name="miscRFNField9"]');
        const miscRFNField7: HTMLInputElement | null = document.querySelector('input[name="miscRFNField7"]');

        if (miscRFNField7) {
            // @ts-ignore
            miscRFNField7.value = App.articleTitle;
        }

        if (miscRFNField9) {
            const articles: Array<cf.MainArticleElement> = this.articleManager.getAllMainArticles();
            // @ts-ignore
            miscRFNField9.value = await this.articleManager.exportObx(articles);
        }

        const miscRFNField6: HTMLInputElement | null = document.querySelector('input[name="miscRFNField6"]');
        const miscRFNField10: HTMLInputElement | null = document.querySelector('input[name="miscRFNField10"]');

        if(miscRFNField6)
        {
            let valueStrings: string[] = [];

            for (let [key, val] of Object.entries(PropertyEditorUI.originalImages)) {
                valueStrings.push(`${key}=${val}`);
            }

            miscRFNField6.value = valueStrings.join(', ');
        }

        if(miscRFNField10)
        {
            let valueStrings: string[] = [];

            for (let [key, val] of Object.entries(PropertyEditorUI.croppedImages)) {
                valueStrings.push(`${key}=${val}`);
            }

            miscRFNField10.value = valueStrings.join(', ');
        }

        ProgressUI.endLoading();

        showRequestAQuoteDialog();
    }

    private async onScreenshotClicked(viewer: Viewer): Promise<void> {
        ProgressUI.beginLoading();

        const blob: Blob = await viewer.createScreenshot({ width: 800, height: 600, mimeType: 'image/jpeg' }, true);

        // create download
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        const msSaveBlob: (blob: Blob, name: string) => void = (navigator as any).msSaveBlob;
        if (msSaveBlob != null) { // for IE
            msSaveBlob(blob, 'Article_configuration.jpg');
        } else {
            const link: HTMLAnchorElement = document.createElement('a');
            link.style.display = 'none';
            document.body.appendChild(link);

            const url: string = URL.createObjectURL(blob);
            link.href = url;
            link.download = 'Article_configuration.jpg';
            link.click();

            URL.revokeObjectURL(url);
            document.body.removeChild(link);
        }

        ProgressUI.endLoading();
    }

    private async buildPropertiesDto() {
        let propertiesDto : Array<PropertyDto> = [];

        PropertyEditorUI.propertiesImage = [];

        for(let a = 0; a < propertyEditor.properties.length; a++)
        {
            var propertyValueImage : PropertyImageDto | null = this.fetchBackgroundFromSelectedPropertyChoice(propertyEditor.properties[a].key);

            let propertyDto = new PropertyDto(
                propertyEditor.properties[a].key,
                propertyEditor.properties[a].getName(),
                propertyEditor.properties[a].getValue()?.text,
                propertyValueImage!
            );

            propertiesDto.push(propertyDto);
        }

        return propertiesDto;
    }

    private async onPDFClicked(articleManager: cf.ArticleManager): Promise<void> {
        ProgressUI.beginLoading();

        let propertiesDto = await this.buildPropertiesDto();

        if(propertiesDto.length > 0) {
            let response = await this.createAndDownloadPdfFile(propertiesDto);
            // @ts-ignore
            let url = response['data']['pdf_url'];

            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';

            xhr.onload = function() {
                if (xhr.status === 200) {
                    const blob = new Blob([xhr.response], { type: 'application/pdf' });
                    const blobUrl = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = 'Rockfon_configuration.pdf';
                    link.style.display = 'none';

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    URL.revokeObjectURL(blobUrl);
                }
            };

            xhr.send();
        }

        ProgressUI.endLoading();
    }

    private fetchBackgroundFromSelectedPropertyChoice(key : string) : PropertyImageDto | null {
        var keyToSearch = key.replace('[Character]', '').replace('[Numeric]', '');
        const propertyHtml = document.querySelector('.' + keyToSearch)!;
        const propertyChoiceSelectedHtml = propertyHtml.querySelector('.selected');

        if(propertyChoiceSelectedHtml) {
            const imgSelector = propertyChoiceSelectedHtml.querySelector('img');

            if(imgSelector) {
                return new PropertyImageDto(
                    key,
                    'image',
                    imgSelector.src
                );
            } else {
                const afterStyle = window.getComputedStyle(propertyChoiceSelectedHtml, ':after');
                let afterBackground = afterStyle.getPropertyValue('background');

                // Use a regular expression to extract the URL value from the CSS value
                const urlRegex = /url\("(.+)"\)/;
                const match = afterBackground.match(urlRegex);
                if (match) {
                    const url = match[1];

                    return new PropertyImageDto(
                        key,
                        'image',
                        url
                    );
                } else {
                    if(afterBackground === 'rgba(0, 0, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box') {
                        return null;
                    }

                    // Use a regular expression to extract the RGBA color value from the string
                    const rgbaRegex = /rgba?\([^)]+\)/;
                    const match = afterBackground.match(rgbaRegex);

                    // If a match is found, save the RGBA color value in a variable
                    let rgbaValue = null;

                    if (match) {
                        rgbaValue = match[0];

                        return new PropertyImageDto(
                            key,
                            'css',
                            rgbaValue
                        );
                    }

                    return null;
                }
            }
        }

        return null;
    }


    private async createAndDownloadPdfFile(propertiesDto: Array<PropertyDto>): Promise<boolean> {
        let data = new FormData();
        let angles = ['front', 'side'];
        let screenshotsInBase64EncodedString: string[] = [];

        // Mesh names to hide
        const meshesToSwitchVisibility = ['lengthLine', 'heightLine', 'widthLine'];

        for (var i = 0; i < angles.length; i++) {
            var camera = this.configureCameraForScreenshot(angles[i]);

            // Hide the meshes
            for (let name of meshesToSwitchVisibility) {
                const mesh = this.coreApp.scene.getMeshByName(name);
                if (mesh) {
                    mesh.visibility = 0;
                }
            }

            // Take the screenshot
            screenshotsInBase64EncodedString.push(
                await Tools.CreateScreenshotUsingRenderTargetAsync(this.engine, camera, 2000)
            );

            // Restore the visibility of the hidden meshes
            for (let name of meshesToSwitchVisibility) {
                const mesh = this.coreApp.scene.getMeshByName(name);
                if (mesh) {
                    mesh.visibility = 1
                }
            }
        }

        data.append('properties', JSON.stringify(propertiesDto));
        data.append('screenshots', JSON.stringify(screenshotsInBase64EncodedString));
        data.append('article_title', App.articleTitle!);
        data.append('base_article_number', App.baseArticleNumber!);

        return fetch(App.baseUrl + 'api/export/pdf',
            {
                method: 'POST',
                body: data,
            }
        )
            .then(function(response) {
                return response.json();
            });
    }

    private async onDownloadClicked(articleManager: cf.ArticleManager, format: '3DS' | 'DWG' | 'DXF' | 'SKP' | 'USDZ' | 'GLB' | 'PEC' ): Promise<void> {
        const url: string | undefined = await this.getExportUrl(articleManager, format);
        if (isNullOrEmpty(url)) {
            return;
        }
        switch (format) {
            case 'DWG':
            case '3DS':
                window.location.href = url;
                break;
        }
    }

    private async getExportUrl(articleManager: cf.ArticleManager, format: '3DS' | 'DWG' | 'DXF' | 'SKP' | 'USDZ' | 'GLB' | 'PEC' ): Promise<string | undefined> {
        ProgressUI.beginLoading();
        let exportOptions: Array<string> | null = null;
        switch (format) {
            case 'DWG':
                exportOptions = [
                    'format=DWG',
                    'hideSubArticles=false',
                    'no2D=true',
                    'textures=true',
                    'materials=true'
                ];
                break;

            case 'DXF':
                exportOptions = [
                    'format=DWG',
                    'dxf=true',
                    'hideSubArticles=false',
                    'no2D=true',
                    'textures=true',
                    'materials=true'
                ];
                break;

            case '3DS':
                exportOptions = [
                    'format=3DS',
                    'hideSubArticles=false',
                    'textures=true'
                ];
                break;

            case 'PEC':
                exportOptions = [
                    'format=PEC',
                    'hideSubArticles=false',
                    'textures=true'
                ];
                break;

            case 'SKP':
                exportOptions = [
                    'format=SKP',
                    'hideSubArticles=false',
                    'no2D=true',
                    'textures=false',
                    'textureToColor=true'
                ];
                break;

            case 'GLB':
                exportOptions = [
                    'format=GLTF',
                    'ascii=false',
                    'texTrans=true',
                    'centerXZ=true'
                ];
                break;

            case 'USDZ':
                exportOptions = [
                    'format=USD',
                    'ascii=false',
                    'centerXZ=true'
                ];
                break;
        }
        try {
            const articles: Array<cf.MainArticleElement> = articleManager.getAllMainArticles();
            
            // export obx
            const obxUrl: string = await articleManager.exportObx(articles);

            // create temporary set article
            const folderId: string = await articleManager.session.basket.insertFolder(null, null, 'Set');
            const setId: string = await articleManager.session.basket.convertToSetArticle(folderId);

            // insert obx into set article
            const pastedIds: Array<string> = await articleManager.session.basket.paste(setId, null, obxUrl);

            // add to set article
            if (pastedIds != null) {
                await articleManager.session.basket.addToSetArticle(pastedIds);

                // export geometry
                const exportUrl: string = await articleManager.session.basket.getExportedGeometry(setId, exportOptions);

                // delete temporary set
                const options: basket.DeleteItemsOptions = new basket.DeleteItemsOptions();
                options.subItems = true;
                await articleManager.session.basket.deleteItems([setId], options);

                // delete temporary obx
                await articleManager.session.deleteFile(obxUrl);
                return exportUrl;
            }
        } catch (error) {
            Log.error('Failed to export geometry. Error: ', error);
        } finally {
            ProgressUI.endLoading();
        }
        return undefined;
    }

    private configureCameraForScreenshot(angle : String) : FreeCamera {
        var center = this.coreApp.model.elements[0].boundingBox.getCenter();

        // Create a camera
        if(App.baseArticleNumber === 'hub') {
            if(angle === 'front') {
                // Bottom, looking up at center
                var camera = new FreeCamera("camera1", new Vector3(center.x, -6.5, center.z), this.coreApp.scene);
                camera.rotation = new Vector3(Math.PI / 2, 0, 0);
                camera.setTarget(center);
            } else {
                // Left side, looking to the right at center
                var camera = new FreeCamera("camera1", new Vector3(center.x, center.y, center.z + 10), this.coreApp.scene);
                //camera.rotation = new Vector3(10, 0, 0);
                camera.setTarget(center);
            }
        } else {
            if(angle === 'front') {
                // Parallel, looking straight ahead at center
                var camera = new FreeCamera("camera1", new Vector3(center.x, center.y, -4), this.coreApp.scene);
                camera.rotation = new Vector3(0, 0, 0);
                camera.setTarget(center);
            } else {
                // Left side, looking to the right at center
                var camera = new FreeCamera("camera1", new Vector3(-3, center.y, 0), this.coreApp.scene);
                camera.setTarget(center);
            }
        }

        return camera;
    }
}